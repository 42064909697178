import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)

const opts = {
  iconfont: 'md',
  theme: {
    themes: {
      light: {
        success: '#2fc59f',
        primary: '#4A6EE0',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#ea1539',
        info: '#2196F3',
        warning: '#ffca28'
      }
    }
  }
}

export default new Vuetify(opts)
