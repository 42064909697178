<template>
  <div class="test">
    <login-form></login-form>
    <!-- <affiliate-form></affiliate-form> -->
  </div>
</template>

<script>
//import AffiliateForm from "@/components/landing/AffiliateForm"
 import LoginForm from "@/components/landing/LoginForm"

export default {
  name: "test",
  components: {
  //  AffiliateForm,
     LoginForm
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
.test {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  color: #42b983;
}
</style>
