<template>
  <div class="login-wrapper">
    <!-- <Logo :isTitle="true" :width="200" :color="'green'" :clickable="true" /> -->
    <section ref="authenticationform" class="authentication-form">
      <div ref="logincontainer" class="login-container">
        <h1 class="title text-center">
          <span>{{$lang.login.member_login}}</span>
        </h1>
        <div class="login-card card" >
               <div v-html="$lang.login.login_desc"></div>
               <span class="referral-note">*{{$lang.login.referral_note}}</span>
            </div>
            
        <div class="card">
          <form class="theme-form">

            <div class="form-group">
              <input
                v-model="email"
                autocomplete="username"
                :disabled="isLoginLoading"
                type="email"
                class="form-control"
                :placeholder="$lang.login.email_address"
              />
            </div>
            <div class="form-group">
              <input
                v-model="password"
                :disabled="isLoginLoading"
                :type="showpassword ? 'password' : 'text'"
                name="login[password]"
                autocomplete="current-password"
                class="form-control"
                :placeholder="$lang.login.password"
              />
              <div v-on:click="showpassword = !showpassword" class="show-hide">
                <span :class="{show:showpassword}"></span>
              </div>
            </div>
            

            <div class="form-button text-center">
              <v-btn
                class="round login"
                color="success"
                :loading="isLoginLoading"
                :disabled="isLoginLoading"
                type="button"
                @click="login"
              >{{$lang.login.login}}</v-btn>
            </div>

            <div class="terms-privacy">
              {{$lang.login.term_of_service_description1}}<a @click="onTerms">{{$lang.login.term_of_service}}</a>
              {{$lang.login.term_of_service_description2}}<a @click="onPrivacy">{{$lang.login.privacy_policy}}</a>
            </div>

          </form>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import systemConnection from '@/connections/system.service'
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      loginError: "",
      errorCode: "",
      showpassword: true,
      isLoginLoading: false
    };
  },
  methods: {
    login: function() {
      const me = this;
      me.isLoginLoading = true;
    //  me.$store.dispatch('reset')
    systemConnection.getUserLoginPromise(me.email, me.password)
        .then(
          function() {
            me.$router.push({ name: "home" });
          },
          function() {
            // me.loginError = me.$lang.error[err.code];
            // me.errorCode = err.code;
          }
        )
        .finally(function() {
          me.isLoginLoading = false;
        });
    },
    onPrivacy(){
      const me = this;
      const routeData = me.$router.resolve({name: 'privacy'});
      window.open(routeData.href, '_blank');
    },
    onTerms(){
      const me = this;
      const routeData = me.$router.resolve({name: 'terms'});
      window.open(routeData.href, '_blank');
    }
  },
  mounted() {
    const me = this;
    me.$refs.authenticationform.addEventListener('keyup', function(event) {
      if (event.keyCode === 13) { 
        me.login();
      }
    });

    setTimeout(function() {
      me.$refs.logincontainer.classList.add("show");
    }, 100);
  }
};
</script>
 
<style>
.v-btn.round {
  width: 100% !important;
  height: 45px !important;
  margin: 20px 0px !important;
}

.authentication-form{
    padding-top: 130px;
}

.authentication-form .card {
  height:auto!important;
}

.login-container {
  padding-top: 150px;
  background: white;
  -webkit-transition: all 200ms ease-in;
  -moz-transition: all 200ms ease-in;
  -o-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  opacity: 0;
}
.login-container.show {
  opacity: 1 !important;
}
.login-container h1.title{
  color:gray;
}
.login-container a{
  text-decoration: none;
}
.v-card.none {
  border: 0;
  box-shadow: none;
}

@media (max-width: 992px) {
  .authentication-form .card {
    width: 90% !important; 
    margin-top: 0px !important;
  }
}
@media only screen and (max-device-width: 600px) {
.authentication-form .card {
    width: 100% !important; 
  margin-top: 0px !important;
  
}
.authentication-form {
  padding-bottom: 0px;
}

}
</style>

<style scoped>
.card {
  -webkit-box-shadow: 0 1px 4px rgba(1, 1, 1, 0.15);
  box-shadow: 0 1px 4px rgba(1, 1, 1, 0.15);
  cursor: default !important;
  min-height: 250px;
  height: auto;
}

.form-unsupported.card{
  min-height: 200px;
}

.creating-message {
  color: #0062ff;
  margin: 0px;
  font-size: 13px;

}
.logo {
    position: absolute;
    top: 42px;
    left: 75px;
}
.login-card.card{
    height:40px!important;
    min-height: 40px;
    text-align: center;
}

.login-wrapper{
  height:100%;
  background: rgb(216 240 236 / 20%);
}

.login-wrapper section{
  height:inherit;
}

.v-btn {
  width: 220px;
  margin: 6px;
}

.social-btns .firebaseui-idp-icon {
  margin-right: 20px;
  margin-bottom: 5px;
}

.social-btns .ggl {
  width: 100%!important;
  background-image: none;
  background-color: white!important;
    color: gray!important;
  text-transform: none !important;
  font-family: Roboto, sans-serif;
      letter-spacing: 0.5px;
}

.social-btns .ggl::before {
  transition: none;
  background-color: white!important;
  background-image: none;
  -webkit-box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)!important;
  box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)!important;
}

.google-button__icon{
  padding: 8px;
}

.social-btns .fb {
  color: white !important;
  text-transform: none !important;
}

.terms-privacy{
  color:#878383;
  font-size:12px;
  padding-bottom: 10px;
  text-align: center;
}

.terms-privacy a{
  text-decoration: underline!important;
  color:gray!important;
}

.referral-note{
    position:absolute;
    color:gray;
    font-size: 12px;
    right:50px;
    bottom:10px;
}
.unsupported-desc{
  width:100%;
  text-align: center;
}
.unsupported-step1{
  margin-top:20px;
}
.unsupported-step2,
.unsupported-step1{
  color:gray;
}

.authentication-form{
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 0px !important;
  padding-top:0px!important;
  
}

.login-container{
  height:inherit;
}

.authentication-form .card {
  border: 0;
  padding: 25px 50px 50px;
  width: 500px;
  margin: 0 auto;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0 1px 10px rgba(1, 1, 1, 0.25);
  background: white;
}

.authentication-form .login-card{
  border-radius: 10px 10px 0px 0px;
  position:relative;
  border-bottom: 3px solid #1ad7dd !important;
}
button{
  border-radius: 20px;
}
a.taption-link {
  text-decoration: underline !important;
  font-size: 14px;
  color: #2fc59f !important;
}
.error-message{
  color:red;
}
.authentication-form .none{
box-shadow: none;
  font-size: 12px;
  text-align: left;
}
</style>