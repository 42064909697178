import { initializeApp } from "firebase/app";
import { initializeFirestore} from 'firebase/firestore';

var config = {};
console.log(process)
if(process.env.VUE_APP_MODE === 'production'){
    config = {
        apiKey: "AIzaSyDJpc8TMcIr17BDmhAt49XLb3bbH84OoLE",
        authDomain: "taption-affiliate.firebaseapp.com",
        databaseURL: "https://taption-affiliate.firebaseio.com",
        projectId: "taption-affiliate",
        storageBucket: "taption-affiliate.firebasestorage.app",
        messagingSenderId: "142958022018",
        appId: "1:142958022018:web:d08b12de82ddcafc65539b",
        measurementId: "G-M4J47Q2R19"
      };
}
else if(process.env.VUE_APP_MODE === 'test'){
    config = {
        apiKey: "AIzaSyDanQU_vhG-f2fwe4PQodpPU0L0zt1dAmY",
        authDomain: "taption-affiliate-test.firebaseapp.com",
        databaseURL: "https://taption-affiliate-test.firebaseio.com",
        projectId: "taption-affiliate-test",
        storageBucket: "taption-affiliate-test.appspot.com",
        messagingSenderId: "794260287849",
        appId: "1:794260287849:web:b36ed4ed1de47d245e0df8",
        measurementId: "G-WXKE7MGQD8"
      };
}


const firebaseApp = initializeApp(config)
initializeFirestore(firebaseApp, {
  // ignoreUndefinedProperties: true,
  experimentalForceLongPolling: true, 
  merge: true
})

 export {firebaseApp};

  
// initializeApp(config)

//   export {firebase};