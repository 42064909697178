<template>
    <div id="app2">
      <v-app>
          <router-view></router-view>
      </v-app>
    </div>
</template>

<script>
export default {
  name: "app",
  created() {
    const me = this;
    me.$lang.setLang("en-US");
  },
};
</script>

<style>
#app {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
#app::before {
  content:"";
  position: absolute;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  opacity: 0.1;
  background-image: url(./assets/login-background.webp);
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
}
h2{
  font-size: 1.25rem!important;
}

input,
h1,
h2,
h3,
h4,
p,
div{
  font-family: Roboto,sans-serif!important;
}

button {
    width: 100%!important;
    height: 45px!important;
    margin: 20px 0!important;
}

input{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #ced4da!important;
    border-radius: 0;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -webkit-box-shadow: 0 0 5px 0 hsla(0,0%,66.7%,.35);
    box-shadow: 0 0 5px 0 hsla(0,0%,66.7%,.35);
}
</style>
