//import firebase from 'firebase'
import { httpsCallable, getFunctions } from "firebase/functions";
import { getAuth, signInWithEmailAndPassword} from 'firebase/auth';


export default {
    // getAdvertisers() {
    //     return firebase.firestore().collection('Affiliates').get()
    //   },
      
    getAdvertiserPlatforms() {
      var cloudFunction = httpsCallable(getFunctions(),'GetSubCollections')

      //  var cloudFunction = firebase.functions().httpsCallable('GetDetailsByMonth')
        return cloudFunction(getAuth().currentUser.uid)


      //   var cloudFunction = firebase.functions().httpsCallable('GetSubCollections')
      //  // getFunctions()
      //   return cloudFunction(id)

      }, 
    getDetailsByMonth(yearmonth) {
      var cloudFunction = httpsCallable(getFunctions(),'GetDetailsByMonth')

      //  var cloudFunction = firebase.functions().httpsCallable('GetDetailsByMonth')
        return cloudFunction(yearmonth)
      },
      getAuthUser () {
        return getAuth().currentUser;
      },
    // getPlatformReferrals(accountId,platform, yearmonth){
    //   const startDate = yearmonth+'00'
    //   const endDate = yearmonth+'32'
    //   return firebase.firestore().collection('Affiliates').doc(accountId).collection(platform).where('date', '>=', Number(startDate)).where('date', '<=', Number(endDate)).get()
    // },
    getUserLoginPromise (email, password) {
      const auth = getAuth();
      return signInWithEmailAndPassword(auth, email, password)
    },
}