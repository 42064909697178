import Vue from 'vue';
import Router from 'vue-router';
import Index from '@/routes/index';
import NotFoundComponent from '@/routes/common/404.vue';
// import AffiliateForm from "@/components/landing/AffiliateForm"
Vue.use(Router);

export default new Router({
    routes: [

    {
        path: '/home',
        name: 'home',
        meta: {
          requiresAuth: true
        },
        components: {
          default: () => import(/* webpackChunkName: "detail" */ '../components/landing/AffiliateForm.vue')
        }
      },
        {
            path: '/404', component: NotFoundComponent
        },
        {
            path: '/',
            name: 'index',
            component: Index
        },
        {
            path: '*', redirect: '/404'
        }
    ]
})
