import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import vuetify from '@/plugins/vuetify'
import {firebaseApp} from './configs/firebase-config'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import './setup';
import "./styles/main.pcss";


let app

Vue.prototype.axios = axios;
Vue.config.productionTip = false

const auth = getAuth(firebaseApp);
//const auth = firebase.auth()
onAuthStateChanged(auth, function () {
//  let sessionTimeout = null
  if (!app) {
    app = new Vue({
      router,
      vuetify,
      store: store,
      render: h => h(App)
    }).$mount('#app')

   // GlobalConfirmationDialog.init(app);
  }
})

// new Vue({
//   store,
//   router,
//   vuetify,
//   render: h => h(App),
// }).$mount('#app')
